<template>
  <div>
    <Hero image="@/assets/hero.jpg" cta="Se våra priser" alt="Kök" route-name="price">
      <p>Vi har lång erfarenhet inom lackering av köksluckor möbler mm. Vi utför alltid ett professionellt arbete med hjälp av våra skickliga medarbetare. Vi är verksamma inom stor Stockholm och hela Sörmland.</p>
    </Hero>
    <h1 style="margin-top:50px;">Några vi arbetar med</h1>
    <b-container>
      <b-row>
        <b-col sm><a href="https://tymc.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/tymc.png" alt="TYMC"/></a></b-col>
        <b-col sm><a href="https://limnells.com/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/limnell.png" alt="Limnell"/></a></b-col>
        <b-col sm><router-link :to="{name: 'tybo'}"><b-img fluid src="@/assets/tybo.png" alt="TYBO"/></router-link></b-col>
        <b-col sm><a href="http://www.mmfmaleri.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/mmf.png" alt="MMF måleri"/></a></b-col>
        <b-col sm><a href="https://www.telge.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/telge.jpg" alt="Telge"/></a></b-col>
      </b-row>
      <b-row>
        <b-col sm><a href="https://www.bruskeentreprenad.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/bruske.png" alt="Bruske Entreprenad"/></a></b-col>
        <b-col sm><a href="https://www.familjebostader.com/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/familjebostäder.jpg" alt="Familjebostäder"/></a></b-col>
        <b-col sm><a href="https://www.stillstroms.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/Stillströms.png" alt="Stillströms industrifärg"/></a></b-col>
        <b-col sm><a href="https://www.siljendahls.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/Siljendahls.png" alt="Bröderna Siljendahls"/></a></b-col>
        <b-col sm><a href="http://www.malerimetoder.se/" target="_blank" rel="noopener noreferrer"><b-img fluid src="@/assets/Målerimetoder.png" alt="Målerimetoder"/></a></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Hero from '../components/Hero'
import { BImg } from 'bootstrap-vue'

export default {
  name: 'HomeView',
  components: {
    Hero,
    BImg
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
